import { Swiper } from 'swiper/react';
import { SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import constant from "../../../Components/Services/constant";
import { useState } from "react";


const ProductSlider = ({galleryItems}) => {


    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [thumbsSwiperVertical, setThumbsSwiperVertical] = useState(null);

    return <div className='product-gallery'>
    <Swiper
        key="1"
        style={{"--swiper-navigation-color": "#fff","--swiper-pagination-color": "#fff",}}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{swiper:thumbsSwiper && !thumbsSwiper.destroyed? thumbsSwiper: null,}}
        autoplay={{delay: 3000,disableOnInteraction: false,}}
        modules={[Autoplay, FreeMode, Navigation, Thumbs]}
        className="galerySwiper"
    >
        {galleryItems
        ? galleryItems.map((value, index) => (
            
            <SwiperSlide key={index + Number(1)}>
                <div class="swiper-slide">
                <figure class="product-image">
                <div class="product-image-item">
                <img src={
                    value.thumbnail
                    ? value.thumbnail
                    : constant.DEFAULT_IMAGE
                } alt="" title="" width="600" height="800" />
                </div>
                </figure>
                </div>
            </SwiperSlide>
        ))
        : null}
    </Swiper>

    <Swiper
        style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
        }}
        onSwiper={setThumbsSwiper}
        direction="vertical"
        loop={false}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={false}
        autoHeight={true}
        watchSlidesProgress={false}
        modules={[FreeMode, Navigation, Thumbs]}
        className="thumbSwiper"
    >
        
        {galleryItems
        ? galleryItems.map((value, index) => (
            <SwiperSlide key={index + Number(1)}>
                <figure class="product-image">
                        <div class="product-image-item">
                            <img src={
                                value.thumbnail
                                ? value.thumbnail
                                : constant.DEFAULT_IMAGE
                            } alt="" title="" width="600" height="800" />
                        </div>
                    </figure>
            </SwiperSlide>
        ))
        : null}
    </Swiper>
    </div>
}

export default ProductSlider
import Footer from "../../Components/Footer"
import CartHeader from "../../Components/Header/cart_header"

const Checkout = () => {
    return (<>
        <CartHeader></CartHeader>
        <p>Checkout</p>
        <Footer></Footer>
    </>)
}

export default Checkout
import Footer from "../../../Components/Footer"
import Header from "../../../Components/Header"
import Accordion from 'react-bootstrap/Accordion';

const BeSpoke = () => {
    return (<>
        <Header></Header>
        <section className="">
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-12">
                        <img src="/img/i.shgcdn.jpg" className="img-fluid"></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title">
                            <h6>WELCOME</h6>
                            <h2>LINLEY Bespoke: Superlative quality, design and craftsmanship. </h2>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <p>At LINLEY we are inspired by transforming an ethereal idea into a reality and we are fortunate enough to work with master craftsmen who turn the impossible into the possible.</p>

                        <p>Everything we create at LINLEY is recognisable by those quintessentially British characteristics of inventiveness, meticulous attention to detail, creativity, elegance, wit and charm.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title">
                            <h2>THE PROCESS</h2>
                        </div>
                        <div style={{ width: "600px" }}>
                            <img src='/img/bespokeimg.avif' style={{ width: '100%', height: '100%' }}></img>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div>
                            <Accordion defaultActiveKey="0" >
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Discovery</Accordion.Header>
                                    <Accordion.Body>
                                        Together with a designer, embark on a bespoke creation. Discover the world of LINLEY and follow a unique brief to produce a piece of LINLEY bespoke furniture. Images will be collated, photographs taken, samples collected.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Design</Accordion.Header>
                                    <Accordion.Body>
                                        ‘It’s your ideas and our design’. The designers will discuss the brief and afterwards you will be presented with sketches, renders and concepts for your LINLEY piece.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Development</Accordion.Header>
                                    <Accordion.Body>
                                        Designs will be refined. Techniques explored and samples created. Upon sign-off of designs, technical drawings will be created for the craftsmen to use. Once the designs are confirmed, our workshops can start producing. During this process, we will regularly update you with progress reports, containing images and comments directly from the workshops.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Delivery</Accordion.Header>
                                    <Accordion.Body>
                                        Two weeks before your LINLEY piece is finished we will be in contact to confirm a suitable date for delivery. Our team will oversee all elements of delivery, in the UK and internationally. We will ensure that your piece is delivered safely and installed where necessary. After delivery, we will follow up to ensure complete satisfaction.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Discovery</Accordion.Header>
                                    <Accordion.Body>
                                        Together with a designer, embark on a bespoke creation. Discover the world of LINLEY and follow a unique brief to produce a piece of LINLEY bespoke furniture. Images will be collated, photographs taken, samples collected.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Design</Accordion.Header>
                                    <Accordion.Body>
                                        ‘It’s your ideas and our design’. The designers will discuss the brief and afterwards you will be presented with sketches, renders and concepts for your LINLEY piece.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Development</Accordion.Header>
                                    <Accordion.Body>
                                        Designs will be refined. Techniques explored and samples created. Upon sign-off of designs, technical drawings will be created for the craftsmen to use. Once the designs are confirmed, our workshops can start producing. During this process, we will regularly update you with progress reports, containing images and comments directly from the workshops.
                                    </Accordion.Body>
                                </Accordion.Item>
                               
                            </Accordion>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4">

                    </div>
                    <div className="col-lg-4">
                        <p>Our bespoke approach reflects David Linley’s inimitable upbringing and education which in turn fostered his ability to juxtapose styles and periods to create pieces of furniture and accessories with distinction and flair.</p>

                        <p>  At LINLEY, we strive for superlative quality, design and craftsmanship. Wit and attention to detail are at the heart of everything that we create. What distinguishes us from many of our competitors is our ability to rise to any challenge and turn our skills to any brief.</p>
                    </div>
                    <div className="col-lg-4">
                        <p>There is no distinct ‘look’, simply a dedicated design ethos that acknowledges the importance of bespoke pieces and craftsmanship. Our aim is to produce heirlooms for the future.</p>

                        <p>The design of a piece always reflects the customer’s personality, the architectural qualities of the space, environment and the client’s desired use.</p>

                        <p>For more information on the LINLEY bespoke commissions please contact us through the form below.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="section-title">
                        <h2>CONTACT US</h2>
                    </div>
                    <div className="col-lg-4">
                            <label for="floatingInput" className="mb-2">Name</label>
                            <input type="text" className="form-control  " id="floatingInput" name="user_fname" placeholder="Enter here..." style={{lineHeight:'2.5'}} />
                    </div>
                    <div className="col-lg-4" >
                            <label for="floatingInput" className="mb-2">Email</label>
                            <input type="text" className="form-control " id="floatingInput" name="user_fname" placeholder="Enter here..." style={{lineHeight:'2.5'}} />
                    </div>
                    <div className="col-lg-4">
                            <label for="floatingInput" className="mb-2">Contact Number</label>
                            <input type="text" className="form-control " id="floatingInput" name="user_fname" placeholder="Enter here..."  style={{lineHeight:'2.5'}}/>
                    </div>
                    <div className="col-lg-12 mt-3">
                            <label for="floatingInput" className="mb-2">Message</label>
                            <textarea type="text" className="form-control " rows={5} id="floatingInput" name="user_fname" placeholder="Enter here..." />
                    </div>

                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className="container-fluid">
                <div className="row">
                    <div className="section-title">
                        <h2>WE HERE TO HELP</h2>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <a href="/contact-us">
                                <img src='/img/spoke1.avif' style={{ width: '100%' }} className="mb-3"></img>
                                <p>Contact Us</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3" >
                        <div>
                            <a href='/'>
                                <img src='/img/spoke2.avif' style={{ width: '100%' }} className="mb-3"></img>
                                <p>Find a Studio</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <a href='/'>
                                <img src='/img/spoke3.avif' style={{ width: '100%' }} className="mb-3"></img>
                                <p>Interior Design Service</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div>
                            <a href="/">
                                <img src='/img/spoke4.avif' style={{ width: '100%' }} className="mb-3"></img>
                                <p>Buying Guides</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)
}


export default BeSpoke

import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Elements/context';

const SearchModal = (props) => {
    const { setsearchQuery, searchQuery, onSearchBttnClick, handleKeyDown } = props
    const contextValues = useContext(DataContext)
    const searchModal = () => {
        contextValues.setToggleSearchModal(!contextValues.toggleSearchModal)
    }
    const recentSearchList = JSON.parse(localStorage.getItem('recentSearch')) || []
    return (<>
        <Modal show={contextValues.toggleSearchModal} onHide={(e) => searchModal()} className='left cartModal'>
            <div className='modal-header'>
                <h2 className="modal-title mb-0">Search</h2>
                <button className='modal-close' onClick={() => { searchModal() }}><i className="ri-close-line"></i></button>
            </div>
            <input onChange={(e) => { setsearchQuery(e.target.value); }} value={searchQuery} onKeyDown={handleKeyDown} ></input>
            {recentSearchList && recentSearchList.length > 0 ? <>
                <div>
                    <ul>
                        {recentSearchList && recentSearchList.map((item, index) => {
                            return (<>
                                <li key={index}>{item}</li>
                            </>)
                        })}
                    </ul>
                </div>
            </> : null}

        </Modal>
    </>)
}


export default SearchModal
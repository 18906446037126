
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ContactForm from './contactform';
import { useContext } from 'react';
import DataContext from '../../Components/Elements/context';




const ContactUs = () => {
    const contextValues = useContext(DataContext)
    return (<>

        <Header></Header>

        <section className="section-gap-medium bg-gradient01">
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-lg-5'>
                        <div className='section-title'>
                            <h1 className='mb-0'>Help Centre</h1>
                        </div>
                    </div>
                    <div className='col-lg-5 justify-content-end'>
                        <p className='mb-0 text-right'>Have a question, need help with your<br></br> selection, or simply after a quote?</p>
                    </div>
                </div>
                <div className='row justify-content-center mt-40'>
                    <div className='col-lg-10'>
                        <img src='/img/contact.jpg' className='img-fluid'></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-gap-medium">
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10'>
                        <p>If you have any questions, please feel free to contact us at any time.</p>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-10'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='contactbox'>
                                    <img src='/img/Chat.svg' className='mb-30'></img>
                                    <h3 className='mb-20'>Live Chat</h3>
                                    <div>
                                        <p className='tx-18 mb-1'>Mon - Fri</p>
                                        <p>9AM-6PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='contactbox'>
                                    <img src='/img/Phone.svg' className='mb-30'></img>
                                    <h3 className='mb-20'>Call Us</h3>
                                    <div>
                                        <a href={`tel:${contextValues.settingsData.admin_support_mobile}`} style={{ color: 'black' }}>
                                            <p>{contextValues.settingsData.admin_support_mobile}</p>
                                        </a>
                                        <p className='tx-18 mb-1'>Mon - Fri</p>
                                        <p>9AM-6PM</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='contactbox'>
                                    <img src='/img/Email.svg' className='mb-30'></img>
                                    <h3 className='mb-20'>Email Us</h3>
                                    <div>
                                        <p className='tx-18 mb-1'>Sales Team</p>
                                        <a href="mailto:sales@rukmancreation.com" style={{ color: 'black' }}>
                                        </a>
                                        <a href={`mailto:${contextValues.settingsData.admin_support_email}`} style={{ color: 'black' }}>
                                            <p>{contextValues.settingsData.admin_support_email}</p>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <ContactForm></ContactForm>
        <section className="section-gap-medium pb-0">
            <div className='container-fluid px-0'>
                <div className='row g-0 align-items-center justify-content-between'>
                    <div className='col-lg-6'>
                        <img src='https://cdn.bfldr.com/8266KQUL/at/mjfcxgbv6gq6nbcqbxjgnmjh/uk-want-to-see-our-products.jpg' className='img-fluid'></img>
                    </div>
                    <div className='col-lg-6'>
                        <div className='p-100'>
                            <div className='section-title'>
                                <h2>Want to see our products in person?</h2>
                                <p>Visit our Chelsea Harbour showroom. </p>
                            </div>
                            <button type='button' className='btn btn-outline-primary btn-lg mt-30'>Go to Location <i class="ri-arrow-right-line"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>



    </>)
}

export default ContactUs
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { ApiService } from "../../Components/Services/apiservices";
import { validEmail } from "../../Components/Elements/Regex";




const Login = () => {
    const navigate = useNavigate();
    const didMountRef = useRef(true)
    const [successMessage, setSuccessMessage] = useState("");
    const [step, setStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loginData, setLoginData] = useState({
        user_email: "",
        user_password: ""
    })
    const [forgetPassword, setforgetPassword] = useState({
        userforget_email: "",
    });

    const handleLoginChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setLoginData(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const loginProcess = () => {

        let counter = 0;
        const loginElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < loginElements.length; i++) {
            if (loginElements[i].value === '') {
                loginElements[i].style.border = '1px solid red';
                counter++;
            } else {
                loginElements[i].style.border = '';
            }
        }
        const dataString = {
            user_email: loginData.user_email,
            user_password: loginData.user_password,
            user_type:1
        }

        if (counter == 0) {
            if (!validEmail.test(loginData.user_email)) {
                setErrorMessage('Invalid Email Format')
                return false;
            }
            
            setButtonLoader(true)
            ApiService.loginProccessPostData("logincheck", dataString).then((res) => {
                if (res.status == "success") {
                    localStorage.setItem("USER_TOKEN", res.user_token)
                    localStorage.removeItem('USER_TRADE_TOKEN')
                    setTimeout(() => {
                        setSuccessMessage(res.message);
                        setButtonLoader(false)
                        window.location.href = '/';
                    }, 1000);
                } else {
                    setButtonLoader(false)
                    setErrorMessage(res.message);
                }
            }).catch(()=>{
                setButtonLoader(false)
            })
        }
    }
    const clearErrorStyles = () => {
        const allInputs = document.querySelectorAll('input');
        allInputs.forEach(input => {
            input.style.border = '';
        });
    }

    const onChangeStep = (step) => {
        setStep(step)
        clearErrorStyles()
        setErrorMessage('')
        setforgetPassword({ userforget_email: '' })
        setLoginData({
            user_email: "",
            user_pass: "",
        })

    }
    const userForgetProcess = () => {
        setErrorMessage("");
        let counter = 0;
        const forgotElements = document.getElementsByClassName("userForgotRequired");
        for (let i = 0; i < forgotElements.length; i++) {
            if (forgotElements[i].value === '') {
                forgotElements[i].style.border = '1px solid red';
                counter++;
            } else {
                forgotElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            if (!validEmail.test(forgetPassword.userforget_email)) {
                setErrorMessage("Invalid Email Format");
                return false;
            }
            setButtonLoader(true)
            const dataString = {
                user_email: forgetPassword.userforget_email,
                user_type:1
            };
            ApiService.loginProccessPostData('forgotpassword', dataString).then((res) => {
                if (res?.status == 'success') {
                    setTimeout(() => {
                        setButtonLoader(false)
                        setStep(3)
                    }, 500);
                } else {
                    setTimeout(() => {
                        setErrorMessage(res.message);
                        setButtonLoader(false)
                    }, 500);
                }
            }).catch((error) => {
                setButtonLoader(false)
            })
        } 
    }

    return (<>
        <Header></Header>
        <section className="section-gap-medium">
            <div className="container">
                <div className="row justify-content-center">
                    {step==1 && ( <div className="col-lg-6">
                        <div className="section-title text-center mb-40">
                            <h2>Sign In</h2>
                        </div>
                       
                        <div className="loginbox mb-20">
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                              <div class="form-floating mb-3">
                                <input type="email" className="form-control loginRequired" id="floatingInput" placeholder="name@example.com"  name='user_email' value={loginData.user_email} onChange={(e) => { handleLoginChange(e) }}/>
                                <label for="floatingInput">Email address</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="password" className="form-control loginRequired" id="floatingPassword" placeholder="Password"  name='user_password' value={loginData.user_password} onChange={(e) => { handleLoginChange(e) }}/>
                                <label for="floatingPassword">Password</label>
                            </div>
                            <button type="button" className="btn btn-primary btn-full btn-lg"disabled={buttonLoader} onClick={() => { loginProcess() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("SIGN IN")}</button>
                            <div className="text-center mt-20 d-flex justify-content-center" style={{ textTransform: 'capitalize' }}>
                                <a href="javascript:void(0)" className="linebutton" onClick={() => { onChangeStep(2) }}>Forgot your password?</a>
                            </div>
                        </div>
                        <p className="text-center">Don't have an account? <a href="/register" className="tx-primary">Register</a></p>
                    </div>)}
                    {step==2 && ( <div className="col-lg-6">
                        <div className="section-title text-center mb-40">
                            <h2>Forgot Password</h2>
                            <p>No problem. Enter your email, We’ll send you a verification link to reset your password.</p>
                        </div>
                       
                        <div className="loginbox mb-20">
                        {errorMessage ? <Alert key={'danger'} className='danger' variant='danger'>{errorMessage}</Alert> : ''}
                        {successMessage ? <Alert key={'success'} className='success' variant='success'>{successMessage}</Alert> : ''}
                              <div class="form-floating mb-3">
                                <input type="email" className="form-control userForgotRequired" id="floatingInput" placeholder="name@example.com"  name='userforget_email' value={forgetPassword.userforget_email} onChange={(e) => {
                                setforgetPassword((prevState) => ({
                                    ...prevState,
                                    'userforget_email': e.target.value,
                                }));
                                setErrorMessage('')
                            }}/>
                                <label for="floatingInput">Email address</label>
                            </div>
                            
                            <button type="button" className="btn btn-primary btn-full btn-lg"disabled={buttonLoader} onClick={() => { userForgetProcess() }}> {buttonLoader ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("SEND EMAIL")}</button>
                        </div>
                        <p className="text-center">Don't have an account? <a href="/register" className="tx-primary">Register</a></p>
                    </div>)}
                    {step==3 && ( <div className="col-lg-6">
                        
                       
                        <div className="loginbox mb-20">
                        <div className="text-center mb-20">
                                    <img src="/img/verify_mail.png" className="wd-100 mb-20" />
                                    <p style={{ textAlign: 'center' }}>Please Check Your email inbox for the password reset link.</p>
                                </div>

                                <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}>Go To Home</button>
                        </div>
                      
                    </div>)}
                   
                </div>
            </div>
        </section>

        <Footer></Footer>
    </>)
}


export default Login
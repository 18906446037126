import { useContext, useState } from "react"
import DataContext from "../../../Components/Elements/context"
import { useNavigate } from "react-router-dom"
import constant from "../../../Components/Services/constant"
import { ApiService } from "../../../Components/Services/apiservices"
import ProductBox from "../../../Components/Elements/Product_box";
import { toast } from "react-toastify";
import { addToCart, addToCartSession } from "../../../Components/Elements/add_to_cart";


const ProductRight = (props) => {
    const navigate = useNavigate()
    const [quantity , setQuantity]= useState(1)
    const { rowProductsData,ufpVariations, arySelectedData,variationDataa,termImageBaseUrl,sendDataToParent,producttabs , galleryItems,selvararray } = props
    const contextValues = useContext(DataContext)
    const variationSelect = (attrTerm, index, valueVariation) => {
        const updatedData = arySelectedData.map(item => {
            if (item.attr === valueVariation.attributes.attribute_name) {
                let terms_value;
                switch (Number(item.terms_type)) {
                    case 1: //Color
                        terms_value = attrTerm.terms_value;
                        break;
                    case 2: //Image
                        terms_value = attrTerm.terms_image;
                        break;
                    case 3: //Text
                        terms_value = attrTerm.terms_name;
                        break;
                    default:
                        terms_value = item.terms_value;
                }
                return { ...item, terms: attrTerm.terms_name, terms_value, terms_type: item.terms_type };
            }
            return item;
        });
        const dataString = {
            variation: updatedData,
            product_id: rowProductsData.product_id,
        };
        ApiService.postData("variation-wise-price", dataString).then((res) => {
            rowProductsData.product_sku = res?.data?.pv_sku;
            rowProductsData.product_selling_price = res?.data?.pv_sellingprice;
            rowProductsData.product_price = res?.data?.pv_price;
            rowProductsData.product_stock = res?.data?.pv_quantity;
            rowProductsData.product_moq = res?.data?.pv_moq;
            rowProductsData.product_discount = res?.data?.pv_discount;
            rowProductsData.product_quantity = res?.data?.pv_discount;
            sendDataToParent(updatedData,res?.arrayImages ,rowProductsData);
           
        });
    };
    const terms = arySelectedData.map(attr => attr.terms).join(',');
    const matchingVariation = rowProductsData?.complete_product_variations?.find(variation => variation.pv_variation === terms);
    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: galleryItems ? galleryItems[0]?.original : constant.DEFAULT_IMAGE,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: variationDataa,
                product_category_id: addproduct.product_category_id,
                selected_variation: arySelectedData,
                product_variation_id: matchingVariation ? matchingVariation?.pv_id : null,
                quantity: Number(quantity),
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, quantity, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, quantity, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    const incrementQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };


    const decrementQuantity = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };
    const addtofav = (addproduct) => {
        const productData = {
            product_id: Number(addproduct.product_id),
            product_name: addproduct.product_name,
            product_slug: addproduct.product_slug,
            product_image: galleryItems ? galleryItems[0]?.original : constant.DEFAULT_IMAGE,
            product_type: Number(addproduct.product_type),
            product_price: parseFloat(addproduct.product_price).toFixed(2),
            product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
            product_discount: addproduct.product_discount,
            product_variation: addproduct?.product_variation,
            product_category_id: addproduct.product_category_id,
            selected_variation: arySelectedData,
            upf_variation_id: matchingVariation ? matchingVariation?.pv_id : 0,
            quantity: 1,
        };
        ApiService.postData("add-to-fav", productData).then((res) => {
            if (res.data.status == "success") {
                let element
                if(matchingVariation?.pv_id>0){
                    element = document.getElementById("icon" + matchingVariation?.pv_id);
                }else{
                    element = document.getElementById("icon" + addproduct.product_id);
                   
                }
                element.classList.remove("ri-heart-3-fill", "ri-heart-3-line");
                element.classList.add(res.data.notification);
                contextValues.setFavCount(res.data.count)
                if (res.data.notification === "ri-heart-3-line") {
                    toast.success('Removed from wishlist');
                } else {
                    toast.success('Added to Wishlist');
                }
            }
        });
    };
    
    return <>
    <div className="product-details">
    {rowProductsData?.product_tag_id && (
            <p className="mb-0 tx-14">{rowProductsData?.product_tag_name.split(',')?.[0]}</p>
        )}
          <div className="title">
        <h1>{rowProductsData?.product_name}</h1>
        {/* {contextValues.userToken ? (
            rowProductsData.ufp_id > 0 ? (
                <button type="button" className="btn-wishlist" onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-fill" id={"icon" + rowProductsData.product_id}></i></button>
            ) : (
                <button type="button" className="btn-wishlist" onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-line" id={"icon" + rowProductsData.product_id}></i></button>
            )
        ) : (
            <button type="button" className="btn-wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-3-line"></i></button>
        )
        } */}
         {contextValues.userToken ? (
                    rowProductsData.ufp_id > 0 ? (
                        matchingVariation?.pv_id > 0 ? (
                            ufpVariations && ufpVariations?.length>0 && ufpVariations ?.includes(matchingVariation?.pv_id) ? <button type="button" className="btn-wishlist" onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-fill" id={"icon" + matchingVariation?.pv_id}></i></button> 
                        : <button type="button" className="btn-wishlist"onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-line" id={"icon" + matchingVariation?.pv_id}></i></button>) : (<button type="button" className="btn-wishlist" onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-fill" id={"icon" + rowProductsData.product_id}></i></button>)

                    ) : (
                        <button type="button" className="btn-wishlist" onClick={(e) => addtofav(rowProductsData)}><i className="ri-heart-3-line" id={"icon" + rowProductsData.product_id}></i></button>
                    )
                ) : (
                    <button type="button" className="btn-wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-3-line"></i></button>
                )
                }
        </div>
        <div className="product-price mt-10">
            {rowProductsData?.product_selling_price && (<ins className="new-price">₹{rowProductsData?.product_selling_price}</ins>)}
            {rowProductsData?.product_price && Number(rowProductsData?.product_price) > Number(rowProductsData?.product_selling_price) && (<del className="old-price"> ₹{rowProductsData?.product_price}</del>)}
        </div>

        {variationDataa.map((valueVariation, indexVariation) => {
            if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) { // Color
                const matchedRow = arySelectedData.find(
                    item => item.attr === valueVariation.attributes.attribute_name
                );
                return (
                    <div className="productVaration" key={indexVariation}>
                        <div className='productVarationName'>{valueVariation.attributes.attribute_name}<span className='ml-5'>: {matchedRow ? matchedRow.terms : ""}</span></div>
                        <div className="productVarationMeta">
                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                const className = stringIncluded ? "color active" : "color";
                                return (
                                    <a
                                        onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                        className={className}
                                        key={indexvalueVariationAttr}
                                        data-src={constant.DEFAULT_IMAGE}
                                        href="javascript:void(0)"
                                        style={{ background: valueVariationAttr.terms_value, }}
                                    ></a>
                                );
                            }
                            )}
                        </div>
                    </div>
                );
            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 2) { //Image

                const matchedRow = arySelectedData.find(
                    item => item.attr === valueVariation.attributes.attribute_name
                );
                return (
                    <div className="productVaration" key={indexVariation}>
                        <div className='productVarationName'>{valueVariation.attributes.attribute_name}<span className='ml-5'>: {matchedRow ? matchedRow.terms : ""}</span></div>
                        <div className="productVarationMeta">
                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                const className = stringIncluded ? "swatch active" : "swatch";
                                return (
                                    <>
                                       <a 
                                    onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                        className={className}
                                        key={indexvalueVariationAttr}
                                        href="javascript:void(0)"
                                    >
                                        <img src={valueVariationAttr.terms_image != null ? termImageBaseUrl + valueVariationAttr.terms_image : constant.DEFAULT_IMAGE} />
                                        
                                    </a>
                                    {valueVariationAttr.terms_name}
                                    
                                    </>
                                 
                                );
                            }
                            )}
                        </div>
                    </div>
                );
            } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 3) { // Text
                const matchedRow = arySelectedData.find(
                    item => item.attr === valueVariation.attributes.attribute_name
                );
                return (
                    <div className="productVaration" key={indexVariation}>
                        <div className='productVarationName'>{valueVariation.attributes.attribute_name}<span className='ml-5'>: {matchedRow ? matchedRow.terms : ""}</span></div>
                        <div className="productVarationMeta">
                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                const className = stringIncluded ? "text active" : "text";
                                return (
                                    <a 
                                    onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                        className={className}
                                        href="javascript:void(0)"
                                        key={indexvalueVariationAttr}
                                    >
                                        {valueVariationAttr.terms_name}
                                    </a>
                                );
                            }
                            )}
                        </div>
                    </div>
                );
            }
            return null;
        })}
        <div className="product-footer mt-30">
            <div className="pqty">
            <span  onClick={()=>{decrementQuantity()}}><i class="ri-subtract-line"></i></span>
                <span>{quantity}</span>
                <span onClick={()=>{incrementQuantity()}}><i class="ri-add-line"></i></span>
            </div>
            <button type="button" className="btn btn-primary btn-full btn-lg" onClick={() => { addToCartProcess(rowProductsData) }}> Add To Cart</button>
        
        </div>
        <div className="product-footer-bottom mt-20">
            <ul>
                <li>Product Details </li>
                <li>Dimensions </li>
                <li>Shipping</li>
            </ul>
        </div>
    </div>
        <div>
       </div>
       {/* {rowProductsData.product_quantity&&<p>Quantity:{rowProductsData.product_quantity}</p>}  */}
       {/* {producttabs && producttabs.length>0 &&(
        producttabs.map((item , index)=>{
            return(<div className="d-flex" key={index}>
                <p>{item.tab_name}</p>
            </div>)
        })
       )} */}
    </>
}

export default ProductRight

import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { ApiService } from '../../Components/Services/apiservices';





const Account = () => {

  const logoutProcess = () => {
    const confirmed = window.confirm("Are you sure ? Want to log out");
    if (confirmed) {
    ApiService.fetchData("logout").then((res) => {
    if (res.status === "success") {
      const user_Token=localStorage.getItem('USER_TOKEN')
      const user_Trade_Token=localStorage.getItem('USER_TRADE_TOKEN')
      if(user_Token && user_Token!==null){
        localStorage.removeItem("USER_TOKEN")
      }
      else if(user_Trade_Token){
        localStorage.removeItem("USER_TRADE_TOKEN")
      }
  
    window.location.href='/'
    }
    })
    }
    }
    return (<>

        <Header></Header>
 
      <p>account</p>

      <button onClick={()=>{logoutProcess()}}>Logout</button>
        <Footer></Footer>



    </>)
}

export default Account
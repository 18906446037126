const CartHeader=()=>{
    return(<>
    <header className="header innerHeaderWhite">
        <div className="container">
            <div className="header-left">
                <a href="/" className="logo">
                <img src="/img/logo-dark.png"></img>
                </a>
            </div>
        </div>
    </header>
    </>)
}

export default CartHeader
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { useState } from "react";

const TradeLogin=()=>{
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [tradeProgram, setTradeProgram] = useState({
    user_fname: '',
    user_lname: '',
    user_company: "",
    title: "",
   

});
const onTodoRegChange = (e) => {
    setErrorMessage('')
    const { name, value } = e.target;
    setTradeProgram((prevState) => ({
        ...prevState,
        [name]: value,
    }));
    if (value !== '') {
        e.target.style.border = '';
    }
}

    return(<>
    <Header></Header>
    <section className="section-gap-small bg-light text-center">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
        <h1>Trade Program</h1>
        </div>
      </div>
    </div>
    </section>   
    <section className="section-gap-small bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="tradebox">
              <div className="tradebox-inner">
                <p><strong>Join our Trade Program today.</strong> Members receive an exclusive 20% discount on all full-price merchandise at Crate & Barrel, CB2 and Crate & Kids, plus even more perks. Complete this short application and a Trade Program Specialist will reach out to you via phone or email within a few business days.</p>
                <div className="row g-3 mt-4">
                  <div className="col-lg-6">
                    <div className="form-group mb-10">
                      <label>First Name <span className="required">*</span></label>
                      <input type="text"placeholder="First Name" name="user_fname" onChange={()=>{onTodoRegChange()}} />
                     
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-10">
                      <label>Last Name <span className="required">*</span></label>
                      <input type="text"placeholder="Last Name" name="user_lname"  onChange={()=>{onTodoRegChange()}} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-10">
                      <label>Name Of Company <span className="required">*</span></label>
                      <input type="text" placeholder="Name Of Company" name='user_company'  onChange={()=>{onTodoRegChange()}}/>
                     
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-10">
                      <label>Title<span className="required">*</span></label>
                      <input type="text" placeholder="Title" name="title" onChange={()=>{onTodoRegChange()}}/>
                    </div>
                  </div>
                </div>
              <div className="row mt-5 align-items-center">
                <div className="col-lg-10">
                  <p className="mb-0 tx-12">To learn more about how we use your information, read our Privacy Policy.</p>
                </div>
                <div className="col-lg-2">
                  <button type="button" className="btn btn-full btn-md btn-primary">Next</button>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
    </>)
}


export default TradeLogin
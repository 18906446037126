
import Header from '../../Components/Header/index'
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from '../../Components/Footer/index'
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
// import { addToCart, addToCartSession } from "../../Component/Element/add_to_cart";
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';
import DataContext from '../../Components/Elements/context';
import { addToCartSession, addToCart } from '../../Components/Elements/add_to_cart';




const Wishlist = () => {
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext)
    const navigate = useNavigate()
    const [listData, setListData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [spinnerloading, setspinnerloading] = useState('')
    const [ImgUrl, setImgurl] = useState('')
    const sessionToken = localStorage.getItem('USER_TOKEN')
    useEffect(() => {
        if (didMountRef.current) {
            getWishlistProduct()
        }
        didMountRef.current = false;
    }, []);
    const getWishlistProduct = () => {
        setisLoading(true)
        ApiService.fetchData('/user-fav-data').then((res) => {
            if (res.status == 'success') {
                setListData(res.favdata)
                setImgurl(res.img_path)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                toast.error(res.message);
                localStorage.removeItem('USER_TOKEN')
                window.location.href = '/'
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    const discount = (value) => {

        var discountPrice = (value.product_price - value.product_selling_price) / value.product_price
        discountPrice = Math.round(discountPrice * 100)
        return discountPrice
    }
    const addtofav = (addproduct) => {
        const productData = {
            product_id: Number(addproduct?.product?.product_id),
            product_name: addproduct?.product?.product_name,
            product_slug: addproduct?.product?.product_slug,
            product_image: addproduct?.product?.product_image ? addproduct?.product?.product_image : constant.DEFAULT_IMAGE,
            product_type: Number(addproduct?.product?.product_type),
            product_price: parseFloat(addproduct?.product?.product_price).toFixed(2),
            product_selling_price: parseFloat(addproduct?.product?.product_selling_price).toFixed(2),
            product_discount: addproduct?.product?.product_discount,
            product_variation: addproduct?.product?.product_variation,
            product_category_id: addproduct?.product?.product_category_id,
            selected_variation: addproduct?.ufp_selected_variation,
            upf_variation_id: addproduct?.product_variation_id ? addproduct?.product_variation_id : 0,
            quantity: 1,
        };
        ApiService.postData("add-to-fav", productData).then((res) => {
            if (res.data.status == "success") {
                let element
                if (addproduct?.product_variation_id) {
                    element = document.getElementById("icon" + addproduct?.product_variation_id);
                } else {
                    element = document.getElementById("icon" + addproduct?.product?.product_id);

                }
                element.classList.remove("ri-heart-3-fill", "ri-heart-3-line");
                element.classList.add(res.data.notification);
                contextValues.setFavCount(res.data.count)
                if (res.data.notification === "ri-heart-3-line") {
                    toast.success('Removed from wishlist');
                } else {
                    toast.success('Added to Wishlist');
                }
            }
        });
    };
    const Removeaddtofav = (product_id) => {
        const dataString = {
            product_id: product_id,
        };
        ApiService.postData("remove-fav-wishlist", dataString).then((res) => {
            if (res.data.status == "success") {
                getWishlistProduct()
            }
        }).catch(() => {
        });
    };
    const addToCartProcess = async (addproduct) => {

        if (parseFloat(addproduct?.product?.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct?.product?.product_id),
                product_name: addproduct?.product?.product_name,
                product_slug: addproduct?.product?.product_slug,
                product_image: addproduct?.product?.product_image,
                product_type: Number(addproduct?.product?.product_type),
                product_price: parseFloat(addproduct?.product?.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct?.product?.product_selling_price).toFixed(2),
                product_discount: addproduct?.product?.product_discount,
                product_variation: addproduct.variationdata,
                product_category_id: addproduct?.product?.product_category_id,
                selected_variation: addproduct.ufp_selected_variation,
                quantity: addproduct.quantity,
                product_variation_id: addproduct?.upf_variation_id ? addproduct?.upf_variation_id : null,

            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct.product.product_id)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    Removeaddtofav(addproduct.product.product_id)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    return (<>

        <Header></Header>
        <div className="subheader ">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/wishlist">Wishlist</Breadcrumb.Item>

                        </Breadcrumb>
                    </Col>
                </Row>
            </Container>

        </div>
        <section className="section-gap-medium">
            <div className="container">
                <div className="row">

                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>My Favourites</h2>
                        </div>
                        {listData && listData.length > 0 ?
                            <div className="row g-3">
                                {listData?.map((item, index) => {
                                    return (<>
                                        <div className='col-4' key={index}>
                                            <div className={'product text-center product-style-1'}>
                                                <div className="product-media-shap">

                                                    <figure className="product-media">
                                                        <a href={"/product/" + item.product.product_slug}>
                                                            <img src={item?.product?.product_image} alt={item?.product?.product_name} />
                                                        </a>
                                                        {contextValues.userToken ? (
                                                            item.ufp_id > 0 ? (

                                                                <button type="button" className="btn-wishlist" onClick={(e) => addtofav(item)}><i className="ri-heart-3-fill" id={"icon" + item?.product_variation_id ? item?.product_variation_id : item?.product?.product_id}></i></button>
                                                            ) : (
                                                                <button type="button" className="btn-wishlist" onClick={(e) => addtofav(item)}><i className="ri-heart-3-line" id={"icon" + item?.product_variation_id ? item?.product_variation_id : item?.product?.product_id}></i></button>
                                                            )
                                                        ) : (
                                                            <button type="button" className="btn-wishlist" onClick={(e) => { navigate('/login') }}><i className="ri-heart-3-line"></i></button>
                                                        )
                                                        }

                                                    </figure>

                                                </div>
                                                <div className="product-details">
                                                    <h2 className="product-name">
                                                        <a href={"/product/" + item?.product?.product_slug}>{item?.product?.product_name}</a>
                                                    </h2>
                                                    <div className="product-price">
                                                        <ins className="new-price">₹ {item?.product?.product_selling_price}</ins>
                                                        {Number(item?.product?.product_price) > Number(item?.product?.product_selling_price) && (<del className="old-price">₹ {item?.product?.product_price}</del>)}
                                                        {discount(item?.product) > 0 && <span className="tx-12 tx-red">{discount(item?.product)}% Off</span>}
                                                    </div>
                                                    <ul >
                                                        {item.product.product_type == 1 && item.ufp_selected_variation && item.ufp_selected_variation.length > 0 ?
                                                            <>
                                                                {item.ufp_selected_variation.map((value, index) => {
                                                                    return (<li key={index}>{value.attr}: {value.terms}</li>)
                                                                })}
                                                            </>
                                                            : null}

                                                    </ul>
                                                    <button onClick={() => { addToCartProcess(item) }}>Add To Cart</button>
                                                </div>
                                            </div>

                                        </div>

                                    </>)
                                })}


                            </div> :
                            <div className='noimg text-center'>
                                <img src='/img/wishlistempty.png' className='mb-20' style={{ width: "300px" }}></img>
                                <h2>See it, love it, save it!</h2>
                                <p className='tx-gray tx-14'>Keep track of your favourite items and inspiration by selecting the heart icon</p>
                                <a href='/' className='btn btn-primary w-lg btn-lg'><span>Continue Shopping</span></a>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>



    </>)
}

export default Wishlist
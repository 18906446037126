
import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import TradeLogin from "../TradeLogin";
import Marquee from "react-fast-marquee";
import TagWiseProducts from "../Element/tag_wise_products";
import CategoryWiseProducts from "../Element/category_wise_products";
import Roomedits from "../Home/Elements/home_room_edits";
import CategoriesList from "../Element/categories_list";


const Pages = () => {
  const { slug } = useParams();
  const navigate = useNavigate()
  const videoRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({});
  const [headerImageUrl, setHeaderImageUrl] = useState("")
  const [mediaImageUrl, setmediaImageUrl] = useState("")
  const [browserSectionData, setBrowserSectionData] = useState([])
  const [mobileSectionData, setMobileSectionData] = useState([])
  useEffect(() => {
    if (didMountRef.current) {
      const getPageData = {
        slug: slug,
      };
      ApiService.postData("pagecontent", getPageData).then((res) => {
        if (res.status === "success") {
          setPageData(res.data);
          setHeaderImageUrl(res.header_image_path)
          setmediaImageUrl(res.media_image_path)
          setBrowserSectionData(res.resAppearanceHomeBrowserData)
          setMobileSectionData(res.resAppearanceHomeMobileData)
          setTimeout(() => {
            const anchor = document.getElementById('tradeloginusertype');
            const handleClick = (e) => {
              e.preventDefault();
              const UserTradeLogin= localStorage.getItem('USER_TRADE_TOKEN')
              if(UserTradeLogin && UserTradeLogin!==null){
                window.location.href='/account'
              }
              else{
                window.location.href='/trade-sign-in'
              }
            };
        
            if (anchor) {
              const UserTradeLogin= localStorage.getItem('USER_TRADE_TOKEN')
              if (UserTradeLogin && UserTradeLogin !== null) {
                anchor.textContent = "Go to Account";
              } else {
                anchor.textContent = "Sign In";
              }
              anchor.addEventListener('click', handleClick);
            }
        
            return () => {
              if (anchor) {
                anchor.removeEventListener('click', handleClick);
              }
            };
          }, 100);
        }
        else if(res.status=='error' && res.message=='Page Not Found') {
          navigate('/error_404')
        }
      });
    }
    didMountRef.current = false;
  });
  const convertStyleStringToObject = (styleString) => {
    if (!styleString) return {}; // Return an empty object if styleString is null or undefined
    return styleString.split(';').reduce((styleObject, styleProperty) => {
      if (styleProperty) {
        const [property, value] = styleProperty.split(':');
        if (property && value) {
          const camelCasedProperty = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
          styleObject[camelCasedProperty] = value.trim();
        }
      }
      return styleObject;
    }, {});
  };
  const handleVideoClick = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      if (videoElement.paused) {
        if (playingIndex !== null && playingIndex !== index) {
          videoRefs.current[playingIndex].pause();
        }
        videoElement.play();
        setPlayingIndex(index);
      } else {
        videoElement.pause();
        setPlayingIndex(null);
      }
    }
  };
 
  return (<>
   <Header innerHeader={'innerHeader'}></Header>
    <BrowserView>
      {pageData.page_show_title == 1 || pageData.page_show_breadcrumb == 1 || pageData.page_header_image ?
        <div className={`subHeader ${pageData.page_header_image ? 'subHeaderwithImg' : ''}`}>
          {pageData.page_header_image && (<img src={headerImageUrl + pageData.page_header_image} className="img-fluid" alt={pageData.page_name} />)}
          <div className="container">
            <Row>
              <Col lg={12}>
                <div className={`${pageData.page_header_image ? 'subHeaderwithImgContent' : 'subHeaderContent'}`}>
                  {pageData.page_show_title == 1 && (<h1>{pageData.page_name}</h1>)}
                  {pageData.page_show_breadcrumb == 1 && (
                    <Breadcrumb>
                      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>
                        {pageData.page_name}
                      </Breadcrumb.Item>
                    </Breadcrumb>)
                  }
                </div>

              </Col>
            </Row>
          </div>
        </div>
        : null}
      {browserSectionData.length > 0 && (
        browserSectionData.map((valueSection, indexSection) => {
          let inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
          if (valueSection.home_layout_image) {
            inlineStyle.backgroundImage = `url(${valueSection.home_layout_image})`;
          }
          if (valueSection.home_layout_background_repeat) {
            inlineStyle.backgroundRepeat = `${valueSection.home_layout_background_repeat}`;
          }
          if (valueSection.home_layout_background_size) {
            inlineStyle.backgroundSize = `${valueSection.home_layout_background_size}`;
          }
          if (valueSection.home_layout_background_position) {
            inlineStyle.backgroundPosition = `${valueSection.home_layout_background_position}`;
          }
          
          return (
            <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
              <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) { //Products
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                     <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                      <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              }
                            } else if (valueRow.home_type === 2) { //Categories
                              dataObj = {
                                'home_cat_title': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                                'home_cat_display_title': valueRow.home_cat_display_title,
                              };
                              return (
                              
                                <CategoriesList
                                  key={indexRow}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) { //Tags
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 4) { //Text
                              return (
                                <div key={indexRow}>
                                  {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }} ></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) { //Images
                              const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                              return (
                                <div className="overlay-zoom">
                                <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                  <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                </a>
                                </div>
                              );
                            } else if (valueRow.home_type === 6) { //Image Gallery
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 7) {  //Video Products
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 8) {  //Brij Team
                              dataObj = {
                                'home_brij_team_type': valueRow.home_brij_team_type,
                                'home_brij_team_value': valueRow.home_brij_team_value,
                              };
                              return (
                                <></>
                                // <TeamList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 9) {  //Brij Award
                              dataObj = {
                                'home_brij_award_type': valueRow.home_brij_award_type,
                                'home_brij_award_display_desc': valueRow.home_brij_award_display_desc,
                                'home_brij_award_value': valueRow.home_brij_award_value,
                              };
                              return (
                                <></>
                                // <AwardList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 10) {  //Brij Event
                              dataObj = {
                                'home_brij_event_type': valueRow.home_brij_event_type,
                                'home_brij_event_value': valueRow.home_brij_event_value,
                              };
                              return (
                                <></>
                                // <EventList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 11) {  //Title
                              const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                              return (
                                <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                  {valueRow.home_brij_heading && React.createElement(
                                    valueRow.home_brij_heading_size || 'h1',
                                    null,
                                    valueRow.home_brij_heading
                                  )}
                                  {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                </div>
                              );
                            } else if (valueRow.home_type === 12) {  //Video
                              return (
                                <div className="fillaboutsec-media withvideolink videosec" onClick={() => { handleVideoClick(indexSection) }} >
                                  <video src={valueRow.home_video} autoPlay="autoplay" loop muted playsInline style={{ width: '100%', height: '100%' }}></video>
                                </div>
                              );
                            } else if (valueRow.home_type === 15) { // Testimonials
                              return (
                                <></>
                                // <TestimonialsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 16) { // Blogs
                              return (
                                <></>
                                // <BlogsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 17) { // Calender
                              return (
                                <></>
                            //   <Calender key={indexRow}></Calender>
                            )
                            }else if (valueRow.home_type === 18) { // Marquee
         
                              return (
                                <>
                                  <Marquee>
                                {valueRow.has_many_gallery.map((gallery, key)=>{
                                  return(<>
                                    <img src={mediaImageUrl+gallery.home_gallery_image_url}  />
                                    </>)
                                })}
                                 </Marquee>
                                </>
                              )
                            }else if (valueRow.home_type === 20) { // Room Edit
                              dataObj = {
                                'has_many_gallery': valueRow.has_many_gallery,
                                'home_roomedit_text': valueRow.home_roomedit_text,
                                'mediaImageUrl':mediaImageUrl
                              };
                              return (
                                <>
                                <Roomedits key={indexRow} dataObj={dataObj}></Roomedits>
                                </>
                              )
                            }
                          })}
                      </div></>
                    ))}
                   
                </div>
              </div>
            
            </section>
          );
        })
      )}
     
     {/* {isClicked && <TradeLogin></TradeLogin>} */}
    </BrowserView>
    <MobileView>
    {pageData.page_show_title == 1 || pageData.page_show_breadcrumb == 1 || pageData.page_header_image ?
        <div className={`subHeader ${pageData.page_header_image ? 'subHeaderwithImg' : ''}`}>
          {pageData.page_header_image && (<img src={headerImageUrl + pageData.page_header_image} className="img-fluid" alt={pageData.page_name} />)}
          <div className="container">
           
          </div>
        </div>
        : null}
     
      {mobileSectionData.length > 0 && (
        mobileSectionData.map((valueSection, indexSection) => {
          const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
          if (valueSection.home_layout_image) {
            inlineStyle.backgroundImage = `url(${valueSection.home_layout_image})`;
          }
          if (valueSection.home_layout_background_repeat) {
            inlineStyle.backgroundRepeat = `${valueSection.home_layout_background_repeat}`;
          }
          if (valueSection.home_layout_background_size) {
            inlineStyle.backgroundSize = `${valueSection.home_layout_background_size}`;
          }
          if (valueSection.home_layout_background_position) {
            inlineStyle.backgroundPosition = `${valueSection.home_layout_background_position}`;
          }
          return (
            <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
              <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) { //Products
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                      <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                     <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              }
                            } else if (valueRow.home_type === 2) { //Categories
                              dataObj = {
                                'home_cat_title': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                                'home_cat_display_title': valueRow.home_cat_display_title,
                              };
                              return (
                                
                                <CategoriesList
                                  key={indexRow}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) { //Tags
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 4) { //Text
                              return (
                                <div key={indexRow}>
                                  {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }} ></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) { //Images
                              const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                              return (
                                <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                  <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                </a>
                              );
                            } else if (valueRow.home_type === 6) { //Image Gallery
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 7) {  //Video Products
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 8) {  //Brij Team
                              dataObj = {
                                'home_brij_team_type': valueRow.home_brij_team_type,
                                'home_brij_team_value': valueRow.home_brij_team_value,
                              };
                              return (
                                <></>
                                // <TeamList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 9) {  //Brij Award
                              dataObj = {
                                'home_brij_award_type': valueRow.home_brij_award_type,
                                'home_brij_award_display_desc': valueRow.home_brij_award_display_desc,
                                'home_brij_award_value': valueRow.home_brij_award_value,
                              };
                              return (
                                <></>
                                // <AwardList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 10) {  //Brij Event
                              dataObj = {
                                'home_brij_event_type': valueRow.home_brij_event_type,
                                'home_brij_event_value': valueRow.home_brij_event_value,
                              };
                              return (
                                <></>
                                // <EventList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 11) {  //Title
                              const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                              return (
                                <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                  {valueRow.home_brij_heading && React.createElement(
                                    valueRow.home_brij_heading_size || 'h1',
                                    null,
                                    valueRow.home_brij_heading
                                  )}
                                  {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                </div>
                              );
                            } else if (valueRow.home_type === 12) {  //Video
                              return (
                                <div className="fillaboutsec-media withvideolink videosec" onClick={() => { handleVideoClick(indexSection) }} >
                                  <video src={valueRow.home_video} autoPlay="autoplay" loop muted playsInline style={{ width: '100%', height: '100%' }}></video>
                                </div>
                              );
                            } else if (valueRow.home_type === 15) { // Testimonials
                              return (
                                <></>
                                // <TestimonialsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 16) { // Blogs
                              return (
                                <></>
                                // <BlogsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 17) { // Calender
                              return (
                                <></>
                            //   <Calender key={indexRow}></Calender>
                            )
                            }else if (valueRow.home_type === 18) { // Marquee
         
                              return (
                                <>
                                  <Marquee>
                                {valueRow.has_many_gallery.map((gallery, key)=>{
                                  return(<>
                                    <img src={mediaImageUrl+gallery.home_gallery_image_url}  />
                                    </>)
                                })}
                                 </Marquee>
                                </>
                              )
                            }else if (valueRow.home_type === 20) { // Room Edit
                              dataObj = {
                                'has_many_gallery': valueRow.has_many_gallery,
                                'home_roomedit_text': valueRow.home_roomedit_text,
                                'mediaImageUrl':mediaImageUrl
                              };
                              return (
                                <>
                                <Roomedits key={indexRow} dataObj={dataObj}></Roomedits>
                                </>
                              )
                            }
                          })}
                      </div></>
                    ))}


                </div>
              </div>
            </section>
          );
        })
      )}
        {/* {isClicked && <TradeLogin></TradeLogin>} */}
      <Footer></Footer>
    </MobileView>
    <Footer></Footer>

  </>)
}

export default Pages
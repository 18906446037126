import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

const BlogDetail=()=>{
    return(<>
    <Header></Header>
    <Footer></Footer>
    </>)
}

export default BlogDetail